<template>
  <div class="main-box" style="min-height: 800px; background-color: #f5f5f5" v-if="isShow">
    <el-form class="form-inline" :inline="true" v-if="storeList && storeList.length>0">
      <el-form-item label="选择门店">
        <el-select v-model="search.store" clearable placeholder="请选择门店" size="small">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
<!--        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>-->
      </el-form-item>
    </el-form>
    <div class="mode-box">
      <div class="item" @click="goLink(2,'创建月财务登记表')">
        <div class="itxt">月</div>
        <div class="name">月财务登记表</div>
      </div>
      <div class="item" @click="goLink(5)">
        <div class="itxt">历</div>
        <div class="name">历史记录</div>
      </div>
    </div>
    <el-dialog
        title="查看记录"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="600px">
      <finance-list :cType="cType" :shopId="search.store" @callFunc="createOk()"></finance-list>
    </el-dialog>
    <el-dialog
        :title="createTitle"
        :visible.sync="dialogPayVisible"
        :destroy-on-close="true"
        width="30%">
      <create-finance :weekInfo="weekInfo" @callFunc="createOk()"></create-finance>
    </el-dialog>
  </div>
</template>
<script>

import CreateFinance from "@/components/CreateFinance";
import FinanceList from "@/components/finance/financeList";
export default {
  components: {
    CreateFinance,
    FinanceList
  },
  data() {
    return {
      info: false,
      isShow: false,
      weekInfo:  {},
      createTitle: '',
      dialogVisible: false,
      dialogPayVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        store: ''
      },
      storeList: [],
      cType: 0
    };
  },
  created() {
    this.getMyInfo()
    if(this.$route.query.msg) {
      this.isShow = true
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    addGroup() {
      this.dialogPayVisible = true
    },
    createOk() {
      this.dialogVisible = false
      this.weekInfo = {}
      this.$api.operate.financeCreate({type:this.cType,shop_id:this.search.store}, res => {
        if(res.errcode==0) {
          this.dialogPayVisible = true
          this.createTitle = '创建月财务登记表'
          this.weekInfo = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    goLink(id,createTitle) {
      this.cType = id
      if(id==5) {
        this.$router.push({name: 'operate_finance', query: {}})
      } else {
        if(this.storeList.length>0 && this.search.store=='') {
          this.fail('请选择门店')
          return false
        }
        this.dialogVisible = true;
        // this.weekInfo = {}
        // this.$api.operate.financeCreate({type:id,shop_id:this.search.store}, res => {
        //   if(res.errcode==0) {
        //     this.createTitle = createTitle
        //     this.dialogPayVisible = true
        //     this.weekInfo = res.data
        //   } else {
        //     this.fail(res.errmsg)
        //   }
        // })
      }
    },
    getSInfo() {
      if(this.isShow) {
        return false
      }
      this.showLoading()
      this.$api.operate.financeCreate({type:2}, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.goDetail(res.data.id)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getMyInfo() {
      this.$api.merchant.myIndex(res => {
        if(res.errcode==0) {
          this.info = res.data
          if(res.data.user_type==1) {
            this.getStoreList()
            this.isShow = true
          } else {
            this.getSInfo()
          }
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    goDetail(id) {
      this.$router.push({name: 'operate_finance_show', query: {id: id}})
    },
  }
};
</script>
<style scoped>
</style>
